<template>
	<div class="laybotnav">
		<router-view class="Router"></router-view>
		<div class="botnavbox">
			<img class="tabball" src="../assets/tabball.png" />
			<cube-tab-bar v-model="selectedLabelDefault" @click="clickHandler" @change="changeHandler" class="botnav">
				<cube-tab v-for="(item, index) in tabs" :label="item.label" :key="index">
					<!-- name为icon的插槽 -->
					<!-- <i slot="icon" :class="item.icon"></i> -->
					<img class="iconpic" :src="selectedLabelDefault == item.label ? item.iconpicactive : item.iconpic" />
					<!-- 默认插槽 -->
					<div class="navlabel">{{ item.label }}</div>
				</cube-tab>
			</cube-tab-bar>
		</div>
	</div>
</template>

<script>
export default {
	name: 'botnav',
	created () {
		switch (this.$route.path) {
			case '/botnav/index':
				this.selectedLabelDefault = '首页';
				break;
			case '/botnav/mine':
				this.selectedLabelDefault = '我的';
				break;
		}
	},
	data () {
		return {
			selectedLabelDefault: '首页',
			tabs: [{
				label: '首页',
				icon: 'cubeic-home',
				iconpic: require('../assets/tabindex.png'),
				iconpicactive: require('../assets/tabindex_active.png'),
			},
			{
				label: '我的',
				icon: 'cubeic-person',
				iconpic: require('../assets/tabmine.png'),
				iconpicactive: require('../assets/tabmine_active.png'),
			}]
		}
	},
	methods: {
		clickHandler (label) {
			// if you clicked home tab, then print 'Home'
			console.log(label)
		},
		//点击与自身不同的其他导航
		changeHandler (label) {
			// if you clicked different tab, this methods can be emitted
			switch (label) {
				case '首页':
					this.$router.push({ path: '/botnav/index' });
					break;
				case '我的':
					this.$router.push({ path: '/botnav/mine' });
					break;
			}
		},
		async submitHandler (e) {
			e.preventDefault()
			try {
				const result = await this.$http.get('/api/login', {
					params: this.model
				})
				if (result.code == '0') {
					this.$store.commit('settoken', result.token)
					window.localStorage.setItem('token', result.token)
				} else {
					alert(result.message)
				}
			} catch (err) {
				console.log(err)
			}
		}
	}
}
</script>

<style scoped lang="less">
.laybotnav {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	position: relative;
	background: url(../assets/indexbg.png) no-repeat center;
	background-size: cover;
	overflow: hidden;
	z-index: 1;
	padding-bottom: 74px;

	.Router {
		position: relative;
		width: 100%;
		z-index: 100;
	}

	.botnavbox {
		position: fixed;
		box-sizing: border-box;
		bottom: 0;
		left: 0;
		z-index: 99;
		height: 74px;
		width: 100%;
		background: url(../assets/tabbg.png) no-repeat left top;
		background-size: 100% 100%;

		.tabball {
			position: absolute;
			width: 55px;
			height: 56px;
			bottom: 10px;
			left: 50%;
			transform: translate(-50%, 0);
			z-index: 100;
		}

		.botnav {
			position: absolute;
			left: 0;
			bottom: 2px;
			z-index: 100;
			width: 100%;
			justify-content: space-between;
			box-sizing: border-box;
			padding: 0 5px;

			.cube-tab {
				width: 30%;
				flex: inherit;
			}

			.iconpic {
				height: 18px;
				width: 18px;
			}

			.cube-tab:last-child .iconpic {
				height: 18px;
				width: 15px;
			}
		}

		.navlabel {
			font-size: 10px;
			color: #747474;
			margin-top: 3px;
			letter-spacing: 1px;
		}

	}

}
</style>
